<template>
  <div>
    <b-badge
      class="mr-1"
      v-if="mileStoneData && mileStoneData.id"
      variant="dark"
      >M : {{ mileStoneData.id }}</b-badge
    >
    <b-badge
      class="mr-1"
      v-if="mileStoneData && mileStoneData.company"
      variant="dark"
      >Lisensee : {{ mileStoneData.company }}</b-badge
    >
    <b-badge
      class="mr-1"
      v-if="mileStoneData && mileStoneData.titles"
      variant="dark"
      >Titles : {{ mileStoneData.titles }}</b-badge
    >

    <b-badge
      class="mr-1"
      v-if="mileStoneData && mileStoneData.amountpayable"
      variant="dark"
      >Amount Payable : {{ mileStoneData.amountpayable }}</b-badge
    >
    <b-badge
      class="mr-1"
      v-if="mileStoneData && mileStoneData.amountpaid"
      variant="dark"
      >Amount Paid : {{ mileStoneData.amountpaid }}</b-badge
    >

    <hr
      style="
        height: 1.5px;
        border-width: 0;
        color: gray;
        background-color: gray;
      "
    />
    <b-row cols="12" class="m-1 alert-heading">
      <b-col class="d-flex align-items-left justify-content-left">
        <b-form-group class="mr-1" style="width: 300px" label="Licensee">
          <v-select
            :clearable="false"
            v-model="selectedCustomer"
            placeholder="Lisensee"
            label="company"
            :options="optionCustomer"
          />
        </b-form-group>
        <b-form-group class="mr-1" style="width: 500px" label="Account">
          <v-select
            @input="setDefaultCurrency"
            :clearable="false"
            v-model="selectedAccount"
            placeholder="Account"
            :options="optionAccounts"
          >
            <template slot="selected-option" slot-scope="option">
              <div>{{ option.currency }} ({{ option.account }})</div>
            </template>

            <template slot="option" slot-scope="option">
              <div>{{ option.currency }} ({{ option.account }})</div>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group
          class="mr-1"
          label="Invoice Revenue Relevant For Royalty Report?"
        >
          <b-form-checkbox
            v-model="relevent_for_royalty"
            class="custom-control-primary"
            id="revenue_upload_validation_child"
            switch
            :unchecked-value="0"
            inline
            >{{ relevent_for_royalty == false ? "No" : "Yes" }}</b-form-checkbox
          >
        </b-form-group>
        <b-form-group class="mr-1" label="check for sent royalty reports?">
          <b-form-checkbox
            v-model="ischeckRoyaltyReport"
            class="custom-control-primary"
            id="revenue_upload_validation_child"
            switch
            :unchecked-value="0"
            inline
            >{{ ischeckRoyaltyReport == false ? "No" : "Yes" }}</b-form-checkbox
          >
        </b-form-group>
      </b-col>
    </b-row>
    <hr
      style="
        height: 1.5px;
        border-width: 0;
        color: gray;
        background-color: gray;
      "
    />

    <div v-if="revenueErrorArray.messageError">
      <a @click="modalError = true"> show errors</a>
    </div>

    <b-card-text class="previewClass">
      <html>
        <head>
          <meta charset="utf-8" />
          <title>Invoice</title>
        </head>
        <body>
          <header>
            <!-- <h1>Invoice</h1> -->
            <address>
              <div
                v-html="orgnizationData ? orgnizationData.address : ''"
              ></div>
              <!-- <p>Praesens-Film AG</p>
                <p>Münchhaldenstrassse 10</p>
                <p>Postfach</p>
                <p>CH-9034 Zürich</p>
                <p>Phone +41 44 422 38 32</p>
                <p>www.praesens.com</p>
                <p>VAT: CHE-102.645.295</p> -->
            </address>
          </header>

          <header class="licensorHeador">
            <address class="licensorClass">
              <p>
                <!-- <b-form-group label="Licensor"> -->
                <!-- </b-form-group> -->
              </p>
              <div v-if="selectedCustomer">
                <p class="vendorNameClass">
                  <b>{{ selectedCustomer ? selectedCustomer.company : "" }}</b>
                </p>
                <p>{{ selectedCustomer ? selectedCustomer.street : "" }}</p>
                <p>
                  {{ selectedCustomer ? selectedCustomer.zip_code : "" }}
                  {{ selectedCustomer ? selectedCustomer.city : "" }}
                </p>
                <p>{{ selectedCustomer ? selectedCustomer.state : "" }}</p>
                <p>
                  {{ selectedCustomer ? selectedCustomer.country_name : "" }}
                </p>
                <!-- <p>{{ selectedCustomer ? selectedCustomer.phone : "" }}</p> -->
              </div>
            </address>
            <address>
              <p>Invoice Date : {{ moment().format("DD/MM/YYYY") }}</p>
            </address>
          </header>

          <article class="scroll">
            <table class="inventory">
              <thead>
                <tr>
                  <th style="width: 33px">
                    <feather-icon icon="TrashIcon" size="16" />
                  </th>
                  <th style="width: 350px"><span>Title</span></th>
                  <th style="width: 200px"><span>Right</span></th>

                  <th style="width: 350px"><span>Territory</span></th>
                  <th style="width: 100px">
                    <span>Price ({{ defaultCurrency }})</span>
                  </th>
                  <th style="width: 100px"><span>Quantity</span></th>
                  <th style="width: 130px">
                    <span>Total Price ({{ defaultCurrency }})</span>
                  </th>

                  <th style="width: 130px">
                    <span>Exch.Rt ({{ defaultCurrency }} to CHF )</span>
                  </th>
                  <th style="width: 130px">
                    <span>Total Price (CHF)</span>
                  </th>
                  <th style="width: 150px">
                    <span>Due Date</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in selectedRevenueItems" :key="index">
                  <td>
                    <div
                      class="border rounded-pill bg-danger d-flex justify-content-center align-items-center text-white"
                      style="height: 20px; width: 20px"
                    >
                      <a @click="removeManualRevenue(item, index)"
                        ><b class="shadow-lg">-</b></a
                      >
                    </div>
                  </td>
                  <td>
                    <span>
                      <v-select
                        @input="onSelectMovie(item, index)"
                        v-model="item.movie"
                        style="border: none !important"
                        :clearable="false"
                        placeholder="Select Movie"
                        label="full_title"
                        :options="optionMovies"
                        class="fix-select"
                    /></span>
                  </td>
                  <td>
                    <!-- Hidden Due To Overflow -->

                    <span>
                      <v-select
                        :reduce="(label) => label.lbl_name"
                        v-model="item.rightIdentifier"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="all_subrights"
                        label="lbl_name"
                        value="lbl_id"
                        placeholder="Select Sub Rights"
                        class="fix-select"
                    /></span>
                  </td>

                  <td>
                    <span
                      ><treeselect
                        @select="onSelectTerritory(index, $event)"
                        v-model="item.territoryMain"
                        style="border: none !important"
                        class="fix-select"
                        :options="trl_territories"
                    /></span>
                  </td>

                  <td>
                    <span>
                      <b-form-input
                        style="border: none !important"
                        contenteditable
                        size="sm"
                        type="number"
                        v-model="item.share_net_single"
                        id="invoice-data-sales-person"
                        placeholder="Price"
                        @input="onUpdateManualAmount(item, index)"
                    /></span>
                  </td>
                  <td>
                    <span
                      ><b-form-input
                        style="border: none !important"
                        contenteditable
                        size="sm"
                        v-model="item.unit_sold"
                        id="invoice-data-sales-person"
                        placeholder="Quant"
                        type="number"
                        @input="onUpdateManualAmount(item, index)"
                    /></span>
                  </td>
                  <td class="text-right">
                    <span>{{ parseFloat(item.share_net).toFixed(2) }}</span>
                  </td>
                  <td>
                    <span
                      ><b-form-input
                        style="border: none !important"
                        contenteditable
                        size="sm"
                        v-model="exchangeRate"
                        id="invoice-data-sales-person"
                        placeholder="Exh. Rt"
                        type="number"
                        @input="onUpdateExchange"
                    /></span>
                  </td>
                  <td>
                    <span
                      ><b-form-input
                        disabled
                        style="border: none !important"
                        size="sm"
                        v-model="item.share_net_final"
                        id="invoice-data-sales-person"
                        placeholder="Final Price"
                        type="number"
                        @input="onUpdateManualAmount()"
                    /></span>
                  </td>
                  <td>
                    <span>
                      <flat-pickr
                        :config="{
                          ...$flatPickrConfig,
                        }"
                        class="form-control"
                        v-model="item.due_date"
                    /></span>
                  </td>
                </tr>
              </tbody>
            </table>
            <a @click="addManualRevenueItem" class="add">+</a>
          </article>
          <aside style="margin-top: -30px !important">
            <h1><span>Other Charges</span></h1>
            <article>
              <table class="inventory">
                <thead>
                  <tr>
                    <th><span>Additional Costs</span></th>
                    <!-- <th>
                      <span>Territory</span>
                    </th> -->
                    <th>
                      <span>Price ({{ defaultCurrency }})</span>
                    </th>
                    <th style="width: 100px"><span>Quantity</span></th>
                    <th>
                      <span>Total Price ({{ defaultCurrency }})</span>
                    </th>
                    <th style="width: 120px">
                      <span>Exchange Rate ({{ defaultCurrency }} to CHF )</span>
                    </th>
                    <th style="width: 120px">
                      <span>Total Price (CHF)</span>
                    </th>
                    <th style="width: 150px">
                      <span>Due Date</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in otherChargesArray" :key="index">
                    <td>
                      <a class="cut" @click="removeOtherCharges(item, index)"
                        >-</a
                      ><span>
                        <b-form-input
                          style="border: none !important"
                          contenteditable
                          size="sm"
                          v-model="item.otherChargeName"
                          id="invoice-data-sales-person"
                          placeholder="Additional Costs"
                          @input="onUpdateAmount()"
                        />
                      </span>
                    </td>
                    <td class="text-right">
                      <span>
                        <b-form-input
                          style="border: none !important"
                          contenteditable
                          size="sm"
                          v-model="item.otherChargeValue"
                          type="number"
                          id="invoice-data-sales-person"
                          placeholder="Amount"
                          @input="onUpdateAmount()"
                      /></span>
                    </td>
                    <td>
                      <span
                        ><b-form-input
                          style="border: none !important"
                          contenteditable
                          size="sm"
                          v-model="item.otherChargeQnt"
                          id="invoice-data-sales-person"
                          placeholder="Quant"
                          type="number"
                          @input="onUpdateAmount(item, index)"
                      /></span>
                    </td>
                    <td class="text-right">
                      <span>{{
                        parseFloat(item.otherChargeTotal).toFixed(2)
                      }}</span>
                    </td>
                    <td>
                      <span
                        ><b-form-input
                          style="border: none !important"
                          contenteditable
                          size="sm"
                          v-model="exchangeRate"
                          id="invoice-data-sales-person"
                          placeholder="Exh. Rt"
                          type="number"
                          @input="onUpdateExchange"
                      /></span>
                    </td>
                    <td>
                      <span
                        ><b-form-input
                          disabled
                          style="border: none !important"
                          size="sm"
                          v-model="item.final_amount"
                          id="invoice-data-sales-person"
                          placeholder="Final Amt"
                          type="number"
                      /></span>
                    </td>
                    <td>
                      <span>
                        <flat-pickr
                          :config="{
                            ...$flatPickrConfig,
                          }"
                          class="form-control"
                          v-model="item.due_date_otherCharge"
                      /></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <a @click="addNewOtherCharge" class="add">+</a>
            </article>
          </aside>
          <article>
            <table class="balance">
              <tr>
                <th>
                  <p>Sub-Total (CHF)</p>
                  <p>Sub-Total ({{ defaultCurrency }})</p>
                </th>
                <td class="text-right">
                  <p>
                    <b>
                      {{
                        roundToTwo(
                          (
                            parseFloat(subTotal) + parseFloat(totalOtherCharge)
                          ).toFixed(2)
                        )
                      }}</b
                    >
                  </p>
                  <p>
                    <b>
                      {{
                        roundToTwo(
                          (
                            parseFloat(subTotalSelectedCurr) +
                            parseFloat(totalOtherChargeSelectedCurr)
                          ).toFixed(2)
                        )
                      }}</b
                    >
                  </p>
                </td>
              </tr>
              <tr>
                <th>
                  <span
                    >VAT %

                    <span class="text-right">
                      <v-select
                        :reduce="(label) => label.lbl_id"
                        @input="onTaxChange"
                        :clearable="false"
                        v-model="selectedTax"
                        placeholder="Select Tax"
                        label="lbl_name"
                        :options="all_tax"
                      />
                      <b-form-input
                        size="sm"
                        contenteditable
                        class="text-right"
                        style="border: none !important"
                        v-model="vatPercentage"
                        id="invoice-data-sales-person"
                        placeholder="Vat%"
                        @input="onUpdateVatPercentage()"
                      /> </span
                  ></span>
                </th>
                <td class="text-right">
                  <span
                    >CHF
                    <b>{{
                      roundToTwo(
                        (
                          (parseFloat(vatPercentage ? vatPercentage : 0) *
                            (parseFloat(subTotal) +
                              parseFloat(totalOtherCharge))) /
                          100
                        ).toFixed(2)
                      )
                    }}</b></span
                  ><br />
                  <span
                    >{{ defaultCurrency }}
                    <b>{{
                      roundToTwo(
                        (
                          (parseFloat(vatPercentage ? vatPercentage : 0) *
                            (parseFloat(subTotalSelectedCurr) +
                              parseFloat(totalOtherChargeSelectedCurr))) /
                          100
                        ).toFixed(2)
                      )
                    }}</b></span
                  >
                </td>
              </tr>
              <tr>
                <th>
                  <p>Total (CHF)</p>
                  <p>Total ({{ defaultCurrency }})</p>
                </th>
                <td class="text-right">
                  <p>
                    <b> {{ totalAmount }}</b>
                  </p>
                  <p>
                    <b> {{ totalAmountSelectedCurr }}</b>
                  </p>
                </td>
              </tr>
            </table>
          </article>

          <article>
            <b> VAT-UID</b>
            <b-form-checkbox
              v-model="isVatUid"
              id="checkbox-1"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >
              VAT-UID :
              <b-form-input
                :disabled="isVatUid == '0' ? true : false"
                size="sm"
                v-model="VatUidNumber"
                id="invoice-data-sales-person"
                placeholder="VAT-UID"
              />
            </b-form-checkbox>
          </article>
          <article>
            <b-form-textarea
              contenteditable
              id="textarea"
              v-model="notes"
              placeholder="Notes/Remarks"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </article>

          <article>
            <b> TAX Liability</b>
            <b-form-checkbox
              v-model="isTaxLiability"
              id="checkbox"
              name="checkbox"
              value="1"
              unchecked-value="0"
            >
              According to the reverse charge system, tax liability transfers to
              the recipient of services
            </b-form-checkbox>
          </article>
        </body>
      </html>
    </b-card-text>
    <b-row>
      <b-col class="m-1 d-flex justify-content-center">
        <b-button
          :disabled="!selectedCustomer ? true : false"
          class="float-right"
          variant="primary"
          @click="showGenerateWarning"
        >
          Generate Invoice</b-button
        >
      </b-col>
    </b-row>

    <div>
      <b-modal
        scrollable
        v-model="modalError"
        id="modalError"
        size="xl"
        hide-footer
      >
        <h4>{{ revenueErrorArray.messageError }}</h4>
        <table>
          <tr>
            <th>Right</th>
            <th>Movie Unique ID</th>
            <th>Territory</th>
            <th>Error</th>
          </tr>
          <tr
            v-for="(error, index) in revenueErrorArray.excelDataErrors"
            :key="index"
          >
            <td>{{ error.rightIdentifier }}</td>
            <td>{{ error.uniqueId }}</td>
            <td>{{ error.territory }}</td>
            <td>{{ error.errorArray }}</td>
          </tr>
        </table>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BAlert,
  BBadge,
  BSpinner,
  BTabs,
  BTab,
  BDropdownDivider,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { getAllVendor, getAllVendor1 } from "@/apiServices/VendorServices";
import vSelect from "vue-select";
import { labels } from "@/utils/constants";
import Treeselect from "@riophae/vue-treeselect";
import { getAllMovie } from "@/apiServices/MovieServices";
import { getOrganizationDetails } from "@/apiServices/OrganizationServices";
import moment from "moment";
import {
  GenerateRevenueInvoicePdf,
  VerifyManualInvoiceItems,
  getAllInvoiceAccounts,
} from "@/apiServices/RevenueServies";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: {
    mileStoneData: {
      type: Object,
      default: {},
    },
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormFile,
    BAlert,
    BBadge,
    BSpinner,
    BTabs,
    BTab,
    BDropdownDivider,
    BFormTextarea,
    vSelect,
    Treeselect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notes: null,
      defaultCurrency: "CHF",
      isTaxLiability: "0",
      isVatUid: "0",
      VatUidNumber: null,
      otherChargesArray: [],
      subTotal: 0,
      subTotalSelectedCurr: 0,
      loading: false,
      totalOtherCharge: 0,
      totalOtherChargeSelectedCurr: 0,
      exchangeRate: 1,
      vatPercentage: 0,
      moment: moment,
      BASE_URL: process.env.VUE_APP_BASEURL,
      tabIndex: 0,
      optionCustomer: [],
      selectedCustomer: null,
      relevent_for_royalty: true,
      ischeckRoyaltyReport: true,
      modalError: false,
      revenueErrorArray: [],
      selectedAccount: null,
      optionAccounts: [],
      trl_rights: [],
      all_rights: [],
      all_subrights: [],
      trl_territories: [],
      all_tax: [],
      selectedTax: null,
      optionMovies: [],
      selectedRevenueItems: [],
      verifiedRevenueData: [],
      orgnizationData: null,
    };
  },
  computed: {
    totalAmount() {
      let amount1 = (
        parseFloat(this.subTotal) + parseFloat(this.totalOtherCharge)
      ).toFixed(2);

      let amount2 = (
        (parseFloat(this.vatPercentage ? this.vatPercentage : 0) *
          (parseFloat(this.subTotal) + parseFloat(this.totalOtherCharge))) /
        100
      ).toFixed(2);

      let total = (parseFloat(amount1) + parseFloat(amount2)).toFixed(2);
      return this.roundToTwo(total);
      // return total;
    },
    totalAmountSelectedCurr() {
      let amount1 = (
        parseFloat(this.subTotalSelectedCurr) +
        parseFloat(this.totalOtherChargeSelectedCurr)
      ).toFixed(2);

      let amount2 = (
        (parseFloat(this.vatPercentage ? this.vatPercentage : 0) *
          (parseFloat(this.subTotalSelectedCurr) +
            parseFloat(this.totalOtherChargeSelectedCurr))) /
        100
      ).toFixed(2);

      let total = (parseFloat(amount1) + parseFloat(amount2)).toFixed(2);
      return this.roundToTwo(total);
      // return total;
    },
  },
  async beforeMount() {
    this.getAllInvoiceAccounts();
    this.GetOriganizationDetails();
    this.GetAllVendor();
    this.GetAllMovie();
    await this.$store.dispatch("master/setLabelMaster", { key: labels.rights });
    this.all_rights = this.$store.state.master.rights;
    await this.$store.dispatch("master/setLabelMaster", {
      key: "sub_right",
      deleted: 0,
    });
    this.all_subrights = this.$store.state.master.sub_right;

    await this.$store.dispatch("master/setLabelMaster", {
      key: "tax",
      deleted: 0,
    });
    this.all_tax = this.$store.state.master.tax;

    if (this.all_tax.length) {
      this.selectedTax = this.all_tax[0].lbl_id;
      this.vatPercentage = this.all_tax[0].lbl_value2;
    }
    await this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.territory_tree,
      master: labels.territory,
      sub_master: labels.sub_territory,
    });

    this.trl_territories = this.$store.state.master.territory_tree;
  },
  mounted() {
    if (this.mileStoneData && this.mileStoneData.milestone_name) {
      this.notes = this.mileStoneData.milestone_name;
    }
  },
  methods: {
    async GetOriganizationDetails() {
      try {
        const response = await getOrganizationDetails();
        if (response.data.status) {
          //console.log(response.data.data);
          this.orgnizationData = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting organization details ", err);
      }
    },

    isValid() {
      console.log(
        this.selectedRevenueItems.length,
        this.otherChargesArray.length
      );
      if (
        this.selectedRevenueItems.length === 0 &&
        this.otherChargesArray.length === 0
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please Select Atleast One Item to Generate Report.",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return false;
      }

      if (this.selectedRevenueItems.length) {
        for (let selectedRevenueItem of this.selectedRevenueItems) {
          if (
            !selectedRevenueItem.rightIdentifier ||
            !selectedRevenueItem.title ||
            !selectedRevenueItem.territory
          ) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title:
                  "Please Select Right, Title & Territory for all revenue items.",
                icon: "EditIcon",
                variant: "failure",
              },
            });
            return false;
          }
        }
      }
      if (this.otherChargesArray.length) {
        for (let otherCharge of this.otherChargesArray) {
          if (!otherCharge.otherChargeName) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Please Enter Additional Cost in all Other Charges.",
                icon: "EditIcon",
                variant: "failure",
              },
            });
            return false;
          }
        }
      }
      return true;
    },
    onTaxChange() {
      let filterTax = this.all_tax.filter((x) => {
        return x.lbl_id == this.selectedTax;
      });
      if (filterTax.length) {
        this.vatPercentage = filterTax[0].lbl_value2;
      }
    },

    onSelectMovie(item, index) {
      this.selectedRevenueItems[index]["title"] = item.movie.title;
      this.selectedRevenueItems[index]["movie_unique_id"] = item.movie.unique;
      console.log(this.selectedRevenueItems, item);
    },

    async onSelectTerritory(index, e) {
      ///console.log(e);
      this.selectedRevenueItems[index].territory = e.label;
      this.selectedRevenueItems[index].territory_name = e.label;
    },

    async GetAllVendor() {
      try {
        const response = await getAllVendor({
          search: "",
          limit: "",
          page: "",
          deleted: "",
        });
        if (response.data) {
          this.optionCustomer = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getAllVendor ", err);
      }
    },

    async getAllInvoiceAccounts() {
      try {
        const response = await getAllInvoiceAccounts();

        if (response.data.data) {
          this.optionAccounts = response.data.data;
          if (this.optionAccounts.length > 0) {
            this.selectedAccount = this.optionAccounts[0];
          }
        }
      } catch (err) {
        console.log("Error in getAllVendor ", err);
      }
    },

    async GetAllMovie() {
      try {
        const response = await getAllMovie();
        if (response.data) {
          this.optionMovies = response.data.data.data;
        }
      } catch (err) {
        console.log("Error in getAllMovie ", err);
      }
    },

    setDefaultCurrency() {
      if (this.selectedAccount && this.selectedAccount.currency) {
        this.defaultCurrency = this.selectedAccount.currency;
      }
    },

    removeOtherCharges(item, index) {
      if (this.otherChargesArray.splice(index, 1)) this.onUpdateAmount();
    },

    roundToTwo(num) {
      if (num && typeof num == "number") {
        num = num.toFixed(2);
      }
      //console.log(num, "num", typeof num);
      if (this.defaultCurrency != "CHF") {
        return num;
      }

      let stringNum = num.toString();
      // if (stringNum) {
      //   let newString = stringNum.split(".");
      //   console.log(newString, "newString");
      // }
      let finalValue;

      try {
        let firstHalf = stringNum.substring(0, stringNum.length - 1);

        console.log();
        if (!firstHalf) {
          return parseFloat(stringNum).toFixed(2);
        }

        if (stringNum && stringNum.split(".").length <= 1) {
          return parseFloat(stringNum).toFixed(2);
        }

        if (["0", "1", "2"].includes(stringNum[stringNum.length - 1])) {
          finalValue = firstHalf + "0";
        } else if (
          ["3", "4", "5", "6", "7"].includes(stringNum[stringNum.length - 1])
        ) {
          finalValue = firstHalf + "5";
        } else if (["8", "9"].includes(stringNum[stringNum.length - 1])) {
          console.log(stringNum, "stringNum");
          finalValue = (Math.ceil(num * 10) / 10).toString() + ".0";
        }

        return parseFloat(finalValue).toFixed(2);
      } catch {
        logger.consoleErrorLog(req.originalUrl, "Error in roundToTwo", error);
        return 0;
      }
    },

    removeManualRevenue(item, index) {
      this.selectedRevenueItems.splice(index, 1);
      this.onUpdateManualAmount();
    },

    showGenerateWarning() {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to generate invoice.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            if (this.relevent_for_royalty == true) {
              this.checkManualInvoiceItems();
            } else {
              this.generateFinalInvoice();
            }
          }
        });
    },

    async checkManualInvoiceItems() {
      if (!this.isValid()) return;
      this.loading = true;
      try {
        let payload = {
          selectedRevenueItems: this.selectedRevenueItems,
          selectedCustomer: this.selectedCustomer,
          ischeckRoyaltyReport: this.ischeckRoyaltyReport == true ? 1 : 0,
        };

        const response = await VerifyManualInvoiceItems(payload);
        console.log(response.data, "res");
        if (response.data.status) {
          this.verifiedRevenueData = response.data.data.verifiedRevenueData;
          console.log(this.verifiedRevenueData);

          this.generateFinalInvoice();
          this.revenueErrorArray = [];
        } else {
          this.revenueErrorArray = response.data.data;
          this.modalError = true;
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error in  VerifyManualInvoiceItems", err);
      }
    },

    async generateFinalInvoice() {
      if (!this.isValid()) return;
      this.loading = true;
      try {
        let payload = {
          selectedRevenueItems: this.selectedRevenueItems,
          verifiedRevenueData: this.verifiedRevenueData,
          selectedCustomer: this.selectedCustomer,
          filterTempMovie: this.filterTempMovie,
          otherChargesArray: this.otherChargesArray,
          tax_id: this.selectedTax,
          milestone_id: this.mileStoneData ? this.mileStoneData.id : null,
          vatPercentage: parseFloat(this.vatPercentage),
          vatAmount: this.roundToTwo(
            (
              (parseFloat(this.vatPercentage ? this.vatPercentage : 0) *
                (parseFloat(this.subTotalSelectedCurr) +
                  parseFloat(this.totalOtherChargeSelectedCurr))) /
              100
            ).toFixed(2)
          ),
          subTotal: this.roundToTwo(
            (
              parseFloat(this.subTotalSelectedCurr) +
              parseFloat(this.totalOtherChargeSelectedCurr)
            ).toFixed(2)
          ),
          total: parseFloat(this.totalAmountSelectedCurr).toFixed(2),
          notes: this.notes,
          exchangeRate: this.exchangeRate,
          defaultCurrency: this.defaultCurrency,
          selectedAccount: this.selectedAccount,
          isTaxLiability: this.isTaxLiability,
          isVatUid: this.isVatUid,
          VatUidNumber: this.VatUidNumber,
          for_royalty_report: this.relevent_for_royalty == true ? 1 : 0,

          is_manual_invoice: 1,
        };

        const response = await GenerateRevenueInvoicePdf(payload);
        if (response.data.status) {
          this.$router.push({
            name: "PreviewInvoice",
            params: {
              revenue_invoice_id: response.data.data.revenue_invoice_id,
            },
          });
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.log("Error in  GenerateRevenueInvoicePdf", err);
      }
    },
    addNewOtherCharge() {
      let obj = {
        otherChargeName: "",
        otherChargeValue: 0,
        otherChargeQnt: 1,
        otherChargeTotal: 0,
        final_amount: 0,
        due_date_otherCharge: null,
      };

      this.otherChargesArray.push(obj);
    },

    onUpdateExchange() {
      this.onUpdateManualAmount();
      this.onUpdateAmount();
    },
    onUpdateManualAmount(itemN, index) {
      if (this.selectedRevenueItems && this.selectedRevenueItems.length > 0) {
        let sum = 0;
        let sumChf = 0;
        this.selectedRevenueItems.forEach((item) => {
          if (item.share_net_single) {
            item.share_net =
              item.share_net_single * (item.unit_sold ? item.unit_sold : 1);

            item.share_net_final = (
              parseFloat(item.share_net ? item.share_net : 0) *
              parseFloat(this.exchangeRate ? this.exchangeRate : 1)
            ).toFixed(2);
          }

          if (item.lbl_name) {
            let getRightName = this.all_rights.filter((y) => {
              return y.lbl_id == item.lbl_name;
            });
            if (getRightName.length > 0) {
              item.rightIdentifier = getRightName[0].lbl_name;
            } else {
              let getSubRightName = this.all_subrights.filter((z) => {
                return z.lbl_id == item.lbl_name;
              });
              if (getSubRightName.length > 0) {
                item.rightIdentifier = getSubRightName[0].lbl_name;
              }
            }
          }

          if (item.share_net_final) {
            sum += parseFloat(item.share_net_final);
            sumChf += parseFloat(item.share_net ? item.share_net : 0);
          }
        });

        this.subTotal = this.roundToTwo(sum);
        this.subTotalSelectedCurr = this.roundToTwo(sumChf);
      } else {
        this.subTotal = 0;
        this.subTotalSelectedCurr = 0;
      }
    },
    onUpdateAmount() {
      if (this.otherChargesArray && this.otherChargesArray.length > 0) {
        let sum = 0;
        let sumChf = 0;
        this.otherChargesArray.forEach((item) => {
          item.otherChargeTotal =
            parseFloat(item.otherChargeValue) *
            parseFloat(item.otherChargeQnt ? item.otherChargeQnt : 1);
          item.final_amount =
            parseFloat(item.otherChargeTotal) *
            parseFloat(this.exchangeRate ? this.exchangeRate : 1);
          if (item.otherChargeValue && item.otherChargeName) {
            sum += parseFloat(item.final_amount);
            sumChf += parseFloat(
              item.otherChargeTotal ? item.otherChargeTotal : 0
            );
          }
        });

        this.totalOtherCharge = this.roundToTwo(sum);
        this.totalOtherChargeSelectedCurr = this.roundToTwo(sumChf);
      } else {
        this.totalOtherCharge = 0;
        this.totalOtherChargeSelectedCurr = 0;
      }
    },
    onUpdateVatPercentage() {},
    addManualRevenueItem() {
      let obj = {
        movie: null,
        territoryMain: null,
        rightIdentifier: "",
        territory: "",
        territory_name: "",
        unit_sold: 1,
        share_net_single: 0,
        share_net: 0,
        share_net_final: 0,
        movie_unique_id: null,
        title: null,
        due_date: null,
      };

      this.selectedRevenueItems.push(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vs__dropdown-menu {
  z-index: 9999999 !important;
  position: absolute !important;
}

.vue-treeselect__menu-container {
  z-index: 9999999 !important;
  position: absolute !important;
}
.vue-treeselect__menu {
  z-index: 9999999 !important;
  position: absolute !important;
}
.licensorClass {
  float: left;
  width: 35%;
}
/* content editable */

*[contenteditable] {
  border-radius: 0.25em;
  min-width: 1em;
  outline: 0;
}

*[contenteditable] {
  cursor: pointer;
}

*[contenteditable]:hover,
*[contenteditable]:focus,
td:hover *[contenteditable],
td:focus *[contenteditable],
img.hover {
  background: #def;
  box-shadow: 0 0 1em 0.5em #def;
}

span[contenteditable] {
  display: inline-block;
}

/* heading */

h1 {
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
}

/* table */

table {
  font-size: 75%;
  table-layout: fixed;
  width: 100%;
}
table {
  border-collapse: separate;
  border-spacing: 2px;
}
th,
td {
  border-width: 1px;
  padding: 0.5em;
  position: relative;
  text-align: left;
}
th,
td {
  border-radius: 0.25em;
  border-style: solid;
}
th {
  background: #eee;
  border-color: #bbb;
}
td {
  border-color: #ddd;
}

/* page */

html {
  font: 16px/1 "Open Sans", sans-serif;
  overflow: auto;
  padding: 0.5in;
}
html {
  background: #999;
  cursor: default;
}

body {
  box-sizing: border-box;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  padding: 0.5in;
  width: 100%;
  border-radius: 5px !important;
  border: 2px solid grey;
}
body {
  background: #fff;
  border-radius: 1px;
  // box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
}

/* header */

header {
  margin: 0 0 3em;
}
header:after {
  clear: both;
  content: "";
  display: table;
}

header h1 {
  background: #000;
  border-radius: 0.25em;
  color: #fff;
  margin: 0 0 1em;
  padding: 0.5em 0;
}
header address {
  float: right;
  font-size: 75%;
  font-style: normal;
  line-height: 1.25;
  margin: 0 1em 1em 0;
}
header address p {
  margin: 0 0 0.25em;
}
header span,
header img {
  display: block;
  float: right;
}
header span {
  margin: 0 0 1em 1em;
  max-height: 25%;
  max-width: 60%;
  position: relative;
}
header img {
  max-height: 100%;
  max-width: 100%;
}
header input {
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* article */

article,
article address,
table.meta,
table.inventory {
  margin: 0 0 3em;
}
article:after {
  clear: both;
  content: "";
  display: table;
}
article h1 {
  clip: rect(0 0 0 0);
  position: absolute;
}

article address {
  float: left;
  font-size: 125%;
  font-weight: bold;
}

/* table meta & balance */

table.meta,
table.balance {
  float: right;
  width: 36%;
}
table.meta:after,
table.balance:after {
  clear: both;
  content: "";
  display: table;
}

/* table meta */

table.meta th {
  width: 40%;
}
table.meta td {
  width: 60%;
}

/* table items */

table.inventory {
  clear: both;
  width: 100%;
}
table.inventory th {
  font-weight: bold;
  text-align: center;
}

table.inventory td:nth-child(1) {
  width: 26%;
}
table.inventory td:nth-child(2) {
  width: 38%;
}
table.inventory td:nth-child(3) {
  text-align: right;
  width: 12%;
}
table.inventory td:nth-child(4) {
  text-align: right;
  width: 12%;
}
table.inventory td:nth-child(5) {
  text-align: right;
  width: 12%;
}

/* table balance */

table.balance th,
table.balance td {
  width: 50%;
}
table.balance td {
  text-align: right;
}

/* aside */

aside h1 {
  border: none;
  border-width: 0 0 1px;
  margin: 0 0 1em;
}
aside h1 {
  border-color: #999;
  border-bottom-style: solid;
}

/* javascript */

.add,
.cut {
  width: 18px !important;
  border-width: 1px;
  display: block;
  font-size: 0.8rem;
  padding: 0.25em 0.5em;
  float: left;
  text-align: center;
  width: 0.6em;
  margin-top: 12px;
}

.add,
.cut {
  background: lightgray;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  border-radius: 0.5em;
  border-color: #0076a3;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  text-shadow: 0 -1px 2px rgba(0, 0, 0, 0.333);
}

.add {
  margin: -2.5em 0 0;
}

.add:hover {
  background: #00adee;
}

.cut {
  opacity: 0;
  position: absolute;
  top: 0;
  left: -1.9em;
  opacity: 1;
}
.cut {
  -webkit-transition: opacity 100ms ease-in;
}

.cut {
  opacity: 1;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }

  .previewClass {
    box-sizing: content-box;
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-style: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
    text-decoration: none !important;
    vertical-align: top !important;
  }

  html {
    background: none;
    padding: 0;
    border: 0;
    box-sizing: content-box;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    vertical-align: top;
  }
  body {
    box-shadow: none;
    margin: 0;
  }
  span:empty {
    display: none;
  }
  .add,
  .cut {
    display: none;
  }
}
p {
  padding: 0px !important;
  font-size: 14px;
}

@page {
  margin: 0;
}
.notes {
  width: 40%;
}
.scroll {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.vs__dropdown-menu {
  z-index: 9999 !important;
  position: relative !important;
}
//
.vue-treeselect__menu-container {
  z-index: 9999 !important;
  position: relative !important;
}
.vue-treeselect__menu {
  z-index: 9999 !important;
  position: relative !important;
}
</style>
