<template>
  <b-card>
    <b-row>
      <b-badge variant="light-primary">{{ `${data.contract_nature}` }} </b-badge
      >-
      <b-badge variant="light-primary"
        >{{ `#C${data.contract_nature[0]}${data.contract_id}` }}
      </b-badge>
      <br />

      <b-form class="p-2">
        <b-form-group label="Movie :- ">
          <p class="value-text">{{ data.titles }}</p>
        </b-form-group>
        <b-form-group label="Licensor/Licensee :- ">
          <p class="value-text">{{ data.company }}</p>
        </b-form-group>
        <b-form-group label="MG :- ">
          <p class="value-text">{{ formatCurrency(data.mgadvance) }}</p>
        </b-form-group>
        <b-form-group label="Title :- ">
          <p class="value-text">{{ data.milestone_name }}</p>
        </b-form-group>
        <b-form-group label="% :- ">
          <p class="value-text">{{ data.payable }}</p>
        </b-form-group>
        <b-form-group label="Amount :- ">
          <p class="value-text">
            {{ formatCurrency(data.amountpayable) }} {{ data.currency }}
          </p>
        </b-form-group>
        <b-form-group label="Planned Date :- ">
          <flat-pickr
            placeholder="Planned Date"
            class="form-control"
            v-model="form.duedate"
            :config="{
              ...$flatPickrConfig,
            }"
          />
        </b-form-group>
        <b-form-group label="Actual Date :- ">
          <flat-pickr
            placeholder="Actual Date"
            class="form-control"
            v-model="form.actualdate"
            :config="{
              ...$flatPickrConfig,
            }"
          />
        </b-form-group>
        <b-form-group label="Comment">
          <b-form-input v-model="form.comment" />
        </b-form-group>
        <b-form-group label="Amount Paid CHF">
          <b-form-input v-model="form.amountpaid" />
        </b-form-group>
        <b-form-group label="Payment Date :- ">
          <flat-pickr
            placeholder="Payment Date"
            class="form-control"
            v-model="form.paymentdate"
            :config="{
              ...$flatPickrConfig,
            }"
          />
        </b-form-group>

        <b-button
          block
          variant="primary"
          @click="
            () => {
              showModal('Are you sure want to submit?', 'save').then(
                (value) => {
                  if (value) saveMilestone();
                }
              );
            }
          "
          >Save</b-button
        >

        <b-button
          v-if="data.contract_nature == 'Sales'"
          @click="onInvoice"
          block
          variant="gradient-success"
          >Generate Invoice</b-button
        >
      </b-form>
    </b-row>

    <b-modal size="xl" id="bv-modal-invoice" hide-footer>
      <b-button
        @click="showContractCollapse"
        size="sm"
        variant="outline-primary"
        >Show Contract Details</b-button
      >
      <b-collapse v-model="contractVisible" id="collapse-1" class="mt-2"
        ><b-card><contact-form :mileStoneData="data" /></b-card>
      </b-collapse>
      <b-collapse class="mt-1" visible id="collapse-3">
        <b-card>
          <manual-inv v-if="showInvModal" :mileStoneData="data"
        /></b-card>
      </b-collapse>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BBadge,
  BModal,
  BCollapse,
} from "bootstrap-vue";
import * as ContractServices from "@/apiServices/ContractServices";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { formatCurrency } from "@/utils/helpers";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import showModal from "../../../components/ModalConfirmation";
import ManualInv from "@/views/pages/Revenue/RevenuePages/CreateManualInvoicePage.vue";
import ContactForm from "@/views/pages/Contracts/ContractsForm.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    flatPickr,
    vSelect,
    BFormSelect,
    BFormSelectOption,
    BBadge,
    BModal,
    ManualInv,
    ContactForm,
    BCollapse,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      form: {
        duedate: "",
        actualdate: "",
        comment: "",
        amountpaid: "",
        paymentdate: "",
      },

      showInvModal: false,
      showContract: false,
      contractVisible: false,
    };
  },
  watch: {
    data(oldVal, newVal) {
      this.form = {
        ...this.form,
        duedate: this.data.duedate,
        actualdate: this.data.actualdate,
        comment: this.data.comment,
        amountpaid: this.data.amountpaid,
        paymentdate: this.data.paymentdate,
      };
    },
  },
  methods: {
    showModal,
    formatCurrency,

    moment,
    showContractCollapse() {
      this.contractVisible = !this.contractVisible;
    },
    onInvoice() {
      this.showInvModal = true;
      this.$bvModal.show("bv-modal-invoice");
    },
    async saveMilestone() {
      try {
        const response = await ContractServices.SaveContractMileStones({
          id: this.data.id,
          ...this.form,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$emit("saved");
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Failed to save",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error in save milestone");
      }
    },
  },
};
</script>

<style>
hr.dotted {
  border-top: 2px dotted #999;
  width: 96%;
}
.value-text {
  color: black;
  font-weight: bold;
}
</style>
