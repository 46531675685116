<template>
  <div>
    <b-tabs pills>
      <b-tab title="Milestones" active
        ><b-card-text><milestone /></b-card-text
      ></b-tab>
      <!-- <b-tab title="Bumpers"><b-card-text>Tab contents 2</b-card-text></b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { BCard, BCardText, BTabs, BTab } from "bootstrap-vue";
import Milestone from "./Milestones/MilestonesList.vue";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    Milestone,
  },
  data() {
    return {};
  },
};
</script>

<style></style>
